import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-none d-md-flex clearfix flex-column flex-lg-row" }
const _hoisted_2 = { class: "d-flex d-md-none clearfix flex-column flex-lg-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChatHistory = _resolveComponent("ChatHistory")!
  const _component_ChatWindow = _resolveComponent("ChatWindow")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_ChatHistory, {
        ref: "chatHistoryWindow",
        onChangeSelectedChat: _ctx.handleSelectChat
      }, null, 8, ["onChangeSelectedChat"]),
      (_openBlock(), _createBlock(_component_ChatWindow, {
        onNewChatCreated: _ctx.handleNewChatCreated,
        onUpdateLatestChat: _ctx.handleUpdateLatestChat,
        data: _ctx.selectedChat,
        key: _ctx.chatWindowKey
      }, null, 8, ["onNewChatCreated", "onUpdateLatestChat", "data"]))
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.selectedChat == null)
        ? (_openBlock(), _createBlock(_component_ChatHistory, {
            key: 0,
            ref: "chatHistoryWindow",
            onChangeSelectedChat: _ctx.handleSelectChat
          }, null, 8, ["onChangeSelectedChat"]))
        : (_openBlock(), _createBlock(_component_ChatWindow, {
            onBackToHistory: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleSelectChat(null))),
            onNewChatCreated: _ctx.handleNewChatCreated,
            onUpdateLatestChat: _ctx.handleUpdateLatestChat,
            data: _ctx.selectedChat,
            key: _ctx.chatWindowKey
          }, null, 8, ["onNewChatCreated", "onUpdateLatestChat", "data"]))
    ])
  ], 64))
}