
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useAbility } from "@casl/vue";
import { defineComponent, ref, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ChatHistoryComponent from "@/components/live-chat/ChatHistory.vue";
import ChatWindow from "@/components/live-chat/ui-fragments/ChatWindow.vue";
import { ChatHistoryEntryObject, SelectChatEvent } from "@/components/live-chat/ts/types";

export default defineComponent({
  name: "live-chat-index",
  components: {
    ChatHistory: ChatHistoryComponent,
    ChatWindow
  },
  setup() {
    const store = useStore();
    const chatWindowKey = ref<number>(0);
    const selectedChat = ref<SelectChatEvent | null>(null);
    onMounted(() => {
      setCurrentPageTitle("Chat");
      selectedChat.value = null;
    });
    function handleSelectChat(event: SelectChatEvent): void {
      selectedChat.value = event;
      chatWindowKey.value++;
    }
    function handleNewChatCreated(event: ChatHistoryEntryObject): void {
      selectedChat.value = {
        userId: null,
        chatId: event.id,
        chatName: selectedChat.value ? selectedChat.value.chatName : "Chat",
        participants: event.is_group == 1 ? event.participants : null
      };
      chatWindowKey.value++;
      store.dispatch("LiveChatModule/addSingleItemToHistory", event);
    }
    function handleUpdateLatestChat(event: ChatHistoryEntryObject): void {
      store.dispatch("LiveChatModule/updateLatestChat", event);
    }
    return {
      handleUpdateLatestChat,
      handleNewChatCreated,
      handleSelectChat,
      chatWindowKey,
      selectedChat
    };
  }
});
